
import { defineComponent, reactive, ref, onUnmounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { NavbarProps } from '@/API/types';
import { parameterSave, getParameterSetup, getParameterData, getAddDays } from '@/API/claim/claimParameter';
import useEventbus from '@/hooks/claim/useEventbus';
import useProgramData from '@/hooks/claim/useProgramData';
import { claimprogramProgramId } from '@/API/claim/claimParameter';
import { getBuListNew } from '@/API/checking/claimDelayRuleSetup';
import { getDefaultSettingList } from '@/API/claim/claimDocumentSample';

import Navbar from '@/components/NavigationBar.vue';
import SubmissionTable from '../ParameterSetUp/components/ParameterClaim/SubmissionTable.vue';
import ExtendInformation from '../ParameterSetUp/components/ParameterClaim/ExtendInformation.vue';
import SupportingDocuments from '../ParameterSetUp/components/ParameterClaim/SupportingDocuments.vue';
import DocSubmissionTable from '../ParameterSetUp/components/ParameterDocument/DocSubmissionTable.vue';
import DocExtendInformation from '../ParameterSetUp/components/ParameterDocument/DocExtendInformation.vue';
import DocSupportingDocuments from '../ParameterSetUp/components/ParameterDocument/DocSupportingDocuments.vue';
import FileNamingRule from '../ParameterSetUp/components/ParameterDocument/FileNamingRule.vue';
import { DocType } from '../ParameterSetUp/types';
export default defineComponent({
  components: {
    Navbar,
    SubmissionTable,
    ExtendInformation,
    SupportingDocuments,
    DocSubmissionTable,
    DocExtendInformation,
    DocSupportingDocuments,
    FileNamingRule
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { customEmit } = useEventbus();
    const radioList = ref<any[]>([]);
    const itemOfferType = ref<any>({});
    //* programId ,offerType数据获取
    //! ---- 从design页面带回来的数据?
    // const { progDesignId, offerTypeList } = useProgramData();
    const { offerTypeList } = useProgramData();
    const progDesignId = route.query.pgmId;
    // 数据回显所需的各模块数据变量定义
    //* claim Method模块数据
    const paraSetupClaimMethodVos = ref<[]>([]);
    //* claim parameter 的 extend information 数据
    const claimClaimParaSetupExtendInfoVos = ref<[]>([]);
    //* claim parameter 的 submission deadline 数据
    const claimParaSetupSubmissionDeadlineVos = ref<any[]>([]);
    //* claim parameter 的 supporting document 数据
    const claimParaSetupSupportingDocVos = ref<[]>([]);
    //* document collecntion parameter 的 extend information 数据
    const docParaSetupExtendInfoVos = ref<[]>([]);
    //* document collecntion parameter 的 file naming rule 数据
    const docParaSetupFileNamingRuleVos = ref<[]>([]);
    //* document collecntion parameter 的 submission deadline 数据
    const docParaSetupSubmissionDeadlineVos = ref<any[]>([]);
    //* document collecntion parameter 的 supporting document 数据
    const docParaSetupSupportingDocVos = ref<[]>([]);
    //* 项目code programCode
    const pragramCod = ref<any>('');
    //! 是否保存？
    const isSaved = ref<any>(true);
    // const pageData = ref<any>({});
    // const designData = ref<any>({});
    // 数据回显
    // const params = { progDesignId: progDesignId.value };
    const bu = ref('');
    const getProgramBuName = async (currentBu: number) => {
      const buList = await getBuListNew();
      const buIdNameMap = buList.reduce((resultMap: any, item: any) => {
        return { ...resultMap, [item.id]: item };
      }, {});
      if (buIdNameMap[currentBu].parentId === null) {
        return buIdNameMap[currentBu].nameEn;
      } else {
        return buIdNameMap[buIdNameMap[currentBu].parentId].nameEn;
      }
    };
    //? claim method 初始化相关
    const getOfferTypeData = () => {
      getParameterData(progDesignId).then(async (data: any) => {
        const modleArr = ref<any[]>([]);
        if (!data.incentiveCategory || data.incentiveCategory.length === 0) {
          message.error('请设置Offer Type');
          return;
        }
        const programBu = parseInt(data.programBu);
        bu.value = await getProgramBuName(programBu);
        data.incentiveCategory &&
          data.incentiveCategory.forEach((item: any) => {
            item.offerType &&
              item.offerType.forEach((i: any) => {
                const geti = {
                  progDesignOfferTypeName: i.nameEn,
                  progDesignOfferTypePathKey: i.pathKey,
                  progDesignOfferTypeId: i.id,
                  incentiveCategory: item.nameEn,
                  defaultDisabled: false
                };
                modleArr.value.push(geti);
              });
          });
        radioList.value = modleArr.value;
        if (radioList.value.length > 0 && Array.isArray(radioList.value)) {
          radioList.value.forEach((item: any) => {
            if (
              item.progDesignOfferTypePathKey === 'fa' ||
              item.progDesignOfferTypePathKey === 'fa_buy_down' ||
              item.progDesignOfferTypePathKey === 'retention'
            ) {
              item.defaultDisabled = true;
              item.claimMethod = '4';
            } else {
              item.claimMethod = '1';
            }
          });
        }
        store.commit('claim/updateClearClaimMethodData');
        store.commit('claim/updateClaimMethodData', radioList.value);
      });
    };

    let firstTabClick = true;
    const clickTab = (activeKey: string) => {
      console.log('activeKey', activeKey);
      if (activeKey === '2' && firstTabClick) {
        customEmit('showDocParaSetupSubmissionData', docParaSetupSubmissionDeadlineVos.value);
        firstTabClick = false;
      }
    };

    //? 获取默认值
    const defaultDateRange = ref(0);
    const getDefaultDate = (customer: any) => {
      //todo 未配置的逻辑
      const customerTypeList = customer
        .reduce((cells: [], ele: any) => {
          return [...cells, ...ele.tableCell];
        }, [])
        .reduce((types: [], item: any) => {
          return [...types, item['Customer.type'].val];
        }, []);
      const SELECTED_DAYS_TYPES = ['Repurchase', 'Trade-in within MB brands', 'Other Brand Trade-in MB']; //todo 文案修改可能会影响到这里
      const isInSelected = SELECTED_DAYS_TYPES.some((ele: string) => {
        return customerTypeList.includes(ele);
      });
      const type = isInSelected ? '1' : '0';
      return getAddDays({ type }).then((res: any) => {
        defaultDateRange.value = res;
      });
    };

    const periodSelectList = ref<any[]>([]);
    const periodMap = reactive<any>({});

    //? submisson deadline 初始化相关
    const initSubmissionDeadline = (tableCellSelect: any, period: any) => {
      const ALLOWED_PERIOD: any[] = [];
      //*  仅take retail date和wholesale date其中一个字段，若两个都维护，仅展示retail date字段
      const attrList = period.map((item: any) => {
        return item['ATTR'].val;
      });
      if (attrList.includes('Sale.retailDate')) {
        ALLOWED_PERIOD.push('Sale.retailDate');
      } else {
        ALLOWED_PERIOD.push('Sale.wholesaleDate');
      }
      tableCellSelect.forEach((item: any) => {
        if (ALLOWED_PERIOD.includes(item.id)) {
          periodMap[item.id] = item.display;
          periodSelectList.value.push(item);
        }
      });
      return ALLOWED_PERIOD;
    };

    //? Supporting Documents 初始化相关
    const docSettings = ref<any>([]);
    const claimSettings = ref<any>([]);
    const getSupportingDocumentsInitListParams = (res: any) => {
      const offerTypeCategoryValues = Object.values(res['offer']).map((type: any) => {
        return type.offerTypeName;
      });
      const customerTypeCategoryValues = res['customer']
        .reduce((allCells: any, type: any) => {
          return [...allCells, ...type.tableCell];
        }, [])
        .map((cell: any) => {
          return cell['Customer.type'].val;
        });
      const specialMarksCategoryValues = [];
      if (res['orderProtection']) specialMarksCategoryValues.push('Order Protection');
      if (res['presale']) specialMarksCategoryValues.push('Pre Sale');
      return [
        {
          bu: bu.value,
          category: 'Offer Type',
          categoryValues: offerTypeCategoryValues,
          tab: 'Claim'
        },
        {
          bu: bu.value,
          category: 'Customer Type',
          categoryValues: customerTypeCategoryValues,
          tab: 'Claim'
        },
        {
          bu: bu.value,
          category: 'Special Mark',
          categoryValues: specialMarksCategoryValues,
          tab: 'Doc'
        }
      ];
    };

    //* 获取 design的数据 从parameter按钮进入
    const getDesignData = () => {
      if (progDesignId) {
        claimprogramProgramId({
          params: { programId: progDesignId }
        }).then((res) => {
          itemOfferType.value = res['offer'] as any;
          pragramCod.value = res.programCode;
          //? submisson deadline 初始化相关
          const tableCellSelect = res.eligibility.period.tableCellSelect;
          const period = res.eligibility.period.tableCell;
          const ALLOWED_PERIOD = initSubmissionDeadline(tableCellSelect, period);
          const periodList = [];
          for (let i = 0; i < period.length; i++) {
            const val = period[i]['ATTR']['val'];
            if (ALLOWED_PERIOD.includes(val)) {
              periodList.push({
                programCode: res.programCode,
                progDesignId: progDesignId,
                eligiblePeriodId: val,
                eligiblePeriodName: periodMap[val],
                eligiblePeriodFrom: period[i]['FROM'].val,
                eligiblePeriodTo: period[i]['TO'].val,
                submissionDateFrom: '',
                submissionDateTo: ''
              });
            }
          }
          claimParaSetupSubmissionDeadlineVos.value = periodList;
          docParaSetupSubmissionDeadlineVos.value = periodList;
          getDefaultDate(res.customer).then((_: any) => {
            customEmit('showClaimParaSetupSubmissionData', claimParaSetupSubmissionDeadlineVos.value);
          });
          //? Supporting Documents 初始化相关
          const params = getSupportingDocumentsInitListParams(res);
          getDefaultSettingList(params).then((res: any) => {
            console.log('Supporting Documents 初始化相关');
            claimSettings.value = res.claimSettings;
            docSettings.value = res.docSettings;
          });
        });
      }
    };

    //? 当从 parameter tab 页进入的时候 获取submission deadline 下拉项
    const getDeadlinDrops = () => {
      if (progDesignId) {
        claimprogramProgramId({
          params: { programId: progDesignId }
        }).then(async (res: any) => {
          //? 获取bu
          const programBu = parseInt(res.businessUnit.selected);
          bu.value = await getProgramBuName(programBu);
          itemOfferType.value = res['offer'] as any;
          pragramCod.value = res.programCode;
          //? submisson deadline 初始化相关
          const tableCellSelect = res.eligibility.period.tableCellSelect;
          const period = res.eligibility.period.tableCell;
          initSubmissionDeadline(tableCellSelect, period);
        });
      }
    };

    // getDesignData();
    //* 从claim后台获取数据
    const getDataFromClaim = () => {
      if (progDesignId) {
        getParameterSetup({
          progDesignId: progDesignId as string
        }).then((res) => {
          if (res.paraSetupClaimMethodVos === null) {
            message.warning('该项目尚未生成Parameter！');
            return;
          }
          paraSetupClaimMethodVos.value = res.paraSetupClaimMethodVos;
          //? 清除vuex 中的 claimMehtodData
          store.commit('claim/updateClearClaimMethodData');
          store.commit('claim/updateClaimMethodData', paraSetupClaimMethodVos.value);
          // 回显claimMethod
          radioList.value = paraSetupClaimMethodVos.value;
          if (radioList.value && radioList.value.length > 0 && Array.isArray(radioList.value)) {
            radioList.value.forEach((item: any) => {
              if (
                item.progDesignOfferTypePathKey === 'fa' ||
                item.progDesignOfferTypePathKey === 'fa_buy_down' ||
                item.progDesignOfferTypePathKey === 'retention'
              ) {
                item.defaultDisabled = true;
              }
            });
          }
          claimClaimParaSetupExtendInfoVos.value = res.claimClaimParaSetupExtendInfoVos;
          claimParaSetupSubmissionDeadlineVos.value = res.claimParaSetupSubmissionDeadlineVos;
          claimParaSetupSupportingDocVos.value = res.claimParaSetupSupportingDocVos;
          docParaSetupExtendInfoVos.value = res.docParaSetupExtendInfoVos;
          docParaSetupSupportingDocVos.value = res.docParaSetupSupportingDocVos;
          docParaSetupFileNamingRuleVos.value = res.docParaSetupFileNamingRuleVos;
          docParaSetupSubmissionDeadlineVos.value = res.docParaSetupSubmissionDeadlineVos;

          customEmit('showClaimParaSetupExtendData', claimClaimParaSetupExtendInfoVos.value);
          customEmit('showClaimParaSetupSubmissionData', claimParaSetupSubmissionDeadlineVos.value);
          customEmit('showClaimParaSetupSupportingData', claimParaSetupSupportingDocVos.value);
          customEmit('showDocParaSetupExtendData', docParaSetupExtendInfoVos.value);
          customEmit('showDocParaSetupSupportingData', {
            supportingDocument: docParaSetupSupportingDocVos.value,
            fileNamingRule: docParaSetupFileNamingRuleVos.value
          });
          // customEmit(
          //     'showDocParaSetupFileNamingData',
          //     docParaSetupFileNamingRuleVos.value
          // );
          customEmit('showDocParaSetupSubmissionData', docParaSetupSubmissionDeadlineVos.value);
        });
      }
    };

    const paramType = route.query.type;
    //? 初始化页面数据
    const initParamData = () => {
      console.log('paramType', paramType);
      if (paramType === 'button') {
        console.log('从Parameter按钮进入');
        //? 从design获取数据
        getOfferTypeData();
        getDesignData();
      } else {
        console.log('从Parameter页签进入');
        getDeadlinDrops();
        getDataFromClaim();
      }
    };
    initParamData();
    // 导航的数据
    const navArr = ref<NavbarProps[]>();
    navArr.value = [
      {
        title: 'Design',
        path: '/programdesign/design',
        id: '1',
        query: {
          pgmId: !route.query.pgmId ? store.state.pgmData.programId : route.query.pgmId
        }
      },
      {
        title: 'Budget',
        path: '/budgetinput',
        id: '2',
        query: {
          pgmId: !route.query.pgmId ? store.state.pgmData.programId : route.query.pgmId
        }
      },
      {
        title: 'Parameter',
        path: '/parametersetup',
        id: '3',
        query: {
          pgmId: !route.query.pgmId ? store.state.pgmData.programId : route.query.pgmId
        }
      }
    ];

    const updateClaimMethod = () => {
      const option = reactive<any>({
        name: 'paraSetupClaimMethodVos',
        rawData: []
      });
      if (radioList.value !== null && radioList.value.length > 0) {
        for (let i = 0; i < radioList.value.length; i++) {
          option.rawData.push({
            progDesignOfferTypeId: radioList.value[i].progDesignOfferTypeId,
            progDesignOfferTypePathKey: radioList.value[i].progDesignOfferTypePathKey,
            progDesignOfferTypeName: radioList.value[i].progDesignOfferTypeName,
            claimMethod: radioList.value[i].claimMethod,
            incentiveCategory: radioList.value[i].incentiveCategory,
            progDesignId: progDesignId
          });
        }
      }
      store.commit('claim/updateSaveParamsData', option);
    };
    const saveHandle = () => {
      // 把progDesignId放到save里
      const optionDesignId = reactive<any>({
        name: 'progDesignId',
        rawData: progDesignId
      });
      store.commit('claim/updateSaveParamsData', optionDesignId);
      // 把programcode放到save里
      const optionProgramCode = reactive<any>({
        name: 'programCode',
        rawData: pragramCod.value
      });
      store.commit('claim/updateSaveParamsData', optionProgramCode);
      updateClaimMethod();
      // claim Params保存存值到vuex
      customEmit('saveParaSetupSubmissionDeadline');
      customEmit('saveParamsExtend');
      customEmit('saveDocParams');
      // document 保存存值到vuex
      customEmit('saveDocSubmissionParams');
      customEmit('saveDocSupportingDocVosParams');
      customEmit('saveDocExtendInfoVosParams');
      customEmit('saveDocFileNamingRuleVosParams');

      // 校验是否选择File Naming Rule
      const unselectedFileRule = store.state.claim.saveParamsData.docParaSetupFileNamingRuleVos.some(
        (item: any) => !item.rule
      );
      if (unselectedFileRule) {
        message.warn('Please select file naming rule before save.');
        return;
      }

      // 触发保存事件
      store.dispatch('claim/saveApi');

      nextTick(() => {
        setTimeout(() => {
          getDataFromClaim();
        }, 1500);
      });
    };
    onUnmounted(() => {
      store.commit('claim/updateClearClaimMethodData');
      store.commit('claim/updateClearParamsData');
      store.commit('claim/updateClearDocDocumentsData');
      store.commit('claim/updateClearExtendInfoData');
    });
    return {
      bu,
      clickTab,
      itemOfferType,
      pragramCod,
      periodMap,
      defaultDateRange,
      periodSelectList,
      //getDesignData,
      navArr,
      activeKey: ref('1'),
      progDesignId,
      radioList,
      offerTypeList,
      saveHandle,
      paraSetupClaimMethodVos,
      claimClaimParaSetupExtendInfoVos,
      claimParaSetupSubmissionDeadlineVos,
      claimParaSetupSupportingDocVos,
      docParaSetupExtendInfoVos,
      docParaSetupFileNamingRuleVos,
      docParaSetupSubmissionDeadlineVos,
      docParaSetupSupportingDocVos,
      paramType,
      claimSettings,
      docSettings
    };
  }
});
