
import { computed, defineComponent, onMounted, ref } from 'vue';
import moment from 'moment';
import useProgramData from '@/hooks/claim/useProgramData';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
interface DataItem {
    key: string;
    id?: string;
    period: string;
    eligiblePeriod: any;
    submissionDate: any;
}

export default defineComponent({
    props: {
        periodMap: {
            type: Object,
            required: true,
            default: function() {
                return {};
            },
        },
        periodSelectList: {
            type: Array,
            required: true,
            default: () => [],
        },
        programCode: {
            type: String,
            required: true,
            default: '',
        },
        defaultDateRange: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    setup(props) {
        const store = useStore();
        const claimMethodData = store.state.claim.claimMethodData;
        const { customOn } = useEventbus();
        const isTake = ref<boolean>(true);
        const columns = [
            {
                title: 'Period',
                dataIndex: 'period',
                width: '140px',
                slots: { customRender: 'period' },
            },
            {
                title: 'Eligible Period',
                dataIndex: 'eligiblePeriod',
                width: '240px',
                slots: { customRender: 'eligiblePeriod' },
            },
            {
                title: 'Submission Date',
                dataIndex: 'submissionDate',
                width: '240px',
                slots: { customRender: 'submissionDate' },
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                slots: { customRender: 'operation' },
            },
        ];
        const dateFormat = 'YYYY-MM-DD';
        const dataSource = ref<DataItem[]>([
            // {
            //     key: '0',
            //     period: '',
            //     eligiblePeriod: [],
            //     submissionDate: [],
            // },
        ]);
        const isSaved = ref<any>([]);
        // const dateRange = (props as any).defaultDateRange;
        //判断两个时间大小，‘1’则第一个时间靠后，’0‘则两个时间相同，‘-1’则第二个时间靠后
        const getLargeTiem = (aTime: any, bTime: any) => {
            const aTimeNum = new Date(aTime).getTime();
            const bTimeNum = new Date(bTime).getTime();
            if (!aTime || !bTime) {
                return false;
            }
            if (aTimeNum - bTimeNum > 0) {
                return '1';
            } else if (aTimeNum === bTimeNum) {
                return '0';
            } else {
                return '-1';
            }
        };
        // 回显数据获取
        const showData = (params: any) => {
            if (params && params.length !== 0) {
                dataSource.value = [];
                for (let i = 0; i < params.length; i++) {
                    const obj: any = {};
                    obj['key'] = `${i}`;
                    obj['period'] = params[i].eligiblePeriodId;
                    obj['progDesignId'] = params[i].progDesignId;
                    obj['programCode'] = params[i].programCode;
                    obj['eligiblePeriodName'] = params[i].eligiblePeriodName;
                    obj['eligiblePeriod'] = [
                        params[i].eligiblePeriodFrom
                            ? moment(params[i].eligiblePeriodFrom, dateFormat)
                            : '',
                        params[i].eligiblePeriodTo
                            ? moment(params[i].eligiblePeriodTo, dateFormat)
                            : '',
                    ];
                    obj['submissionDate'] = [
                        params[i].submissionDateFrom
                            ? moment(params[i].submissionDateFrom, dateFormat)
                            : '',
                        params[i].submissionDateTo
                            ? moment(params[i].submissionDateTo, dateFormat)
                            : '',
                    ];
                    if (params[i].submissionDateFrom === '') {
                        obj['submissionDate'][0] = moment(
                            params[i].eligiblePeriodFrom,
                            dateFormat
                        );
                    }
                    if (params[i].submissionDateTo === '') {
                        obj['submissionDate'][1] = moment(
                            params[i].eligiblePeriodTo,
                            dateFormat
                        ).add((props as any).defaultDateRange, 'days');
                    }
                    dataSource.value.push(obj);
                }
            }
        };
        const {
            progDesignId,
            periodList,
            periodPgmList,
            periodSelectList,
        } = useProgramData();
        const timeAreaLst = ref<any>('');
        // const getDesign = ()=>{
        //     if (isSaved.value===false){
        //         claimprogramProgramId({params: { programId: progDesignId.value }}).then((res)=>{
        //             // console.log('res169',res)
        //             if (res.eligibility.period&&res.eligibility.period.tableCell){
        //                 res.eligibility.period.tableCellSelect.length>0&&res.eligibility.period.tableCellSelect.forEach((item: any,index: any)=>{
        //                     res.eligibility.period.tableCell.forEach((paI: any,paInd: any)=>{
        //                         if (paI.ATTR.val===item.id){
        //                             paI.display = item.display
        //                         }
        //                     })
        //                 })
        //                 timeAreaLst.value = res.eligibility.period.tableCell
        //                 console.log('timeLst113',timeAreaLst.value)
        //                 dataSource.value = []
        //                 for (let i = 0; i < timeAreaLst.value.length; i++) {
        //                     const obj: any = {}
        //                     obj['key'] = `${i}`
        //                     obj['period'] = timeAreaLst.value[i].display
        //                     obj['eligiblePeriod'] = [timeAreaLst.value[i].FROM.val?timeAreaLst.value[i].FROM.val:'', timeAreaLst.value[i].TO.val?timeAreaLst.value[i].TO.val:'']
        //                     obj['submissionDate'] = [timeAreaLst.value[i].submissionDateFrom?moment(timeAreaLst.value[i].submissionDateFrom, dateFormat):'', timeAreaLst.value[i].submissionDateTo?moment(timeAreaLst.value[i].submissionDateTo, dateFormat):'']
        //                     dataSource.value.push(obj)
        //                 }
        //             }
        //         })
        //     }
        // }
        const selectPeriod = (value: string, record: any) => {
            record.eligiblePeriodName = (props as any).periodMap[value];
        };

        const onDelete = (key: string) => {
            dataSource.value = dataSource.value.filter(
                (item) => item.key !== key
            );
        };
        const count = computed(() => dataSource.value.length + 1);
        const handleAdd = () => {
            // 新增行的period与上一行的period一致
            let period = '';
            if (dataSource.value.length > 0) {
                const lastIndex = dataSource.value.length - 1;
                period = dataSource.value[lastIndex]['period'];
            }
            const newData = {
                key: `${count.value}`,
                period: period,
                eligiblePeriodName: (props as any).periodMap[period],
                eligiblePeriod: [],
                submissionDate: [],
                programCode: (props as any).programCode,
            };

            dataSource.value.push(newData);
        };
        const saveDocSubmissionParams = () => {
            const queryParams = ref<any[]>([]);
            dataSource.value.forEach((item) => {
                queryParams.value.push({
                    eligiblePeriodFrom: item.eligiblePeriod[0]?.format(
                        dateFormat
                    ),
                    eligiblePeriodId: (item as any).period,
                    eligiblePeriodName: (item as any).eligiblePeriodName,
                    eligiblePeriodTo: item.eligiblePeriod[1]?.format(
                        dateFormat
                    ),
                    progDesignId: progDesignId.value,
                    submissionDateFrom: item.submissionDate[0]?.format(
                        dateFormat
                    ),
                    submissionDateTo: item.submissionDate[1]?.format(
                        dateFormat
                    ),
                    programCode: (item as any).programCode,
                });
                // const curDataObj = item;
                // const { key, ...filterDataObj } = curDataObj;
                // if (
                //     Object.values(filterDataObj)
                //         .join('')
                //         .trim() !== ''
                // ) {
                //     const periodName = ref<string>('');
                //     for (const key of periodPgmList.value) {
                //         if (item.period === key['display']) {
                //             periodName.value = key['ATTR']['val'];
                //         }
                //     }
                //     queryParams.value.push({
                //         eligiblePeriodFrom: item.eligiblePeriod[0]?.format(
                //             dateFormat
                //         ),
                //         eligiblePeriodId: periodName.value,
                //         eligiblePeriodName: item.period,
                //         eligiblePeriodTo: item.eligiblePeriod[1]?.format(
                //             dateFormat
                //         ),
                //         progDesignId: progDesignId.value,
                //         submissionDateFrom: item.submissionDate[0]?.format(
                //             dateFormat
                //         ),
                //         submissionDateTo: item.submissionDate[1]?.format(
                //             dateFormat
                //         ),
                //     });
                // }
            });
            const option = {
                name: 'docParaSetupSubmissionDeadlineVos',
                rawData: queryParams.value,
            };
            store.commit('claim/updateSaveParamsData', option);
        };
        onMounted(() => {
            customOn('saveDocSubmissionParams', saveDocSubmissionParams);
            customOn('showDocParaSetupSubmissionData', showData);
        });
        const handleChangeEP = (date: any, key: any, rowItem: any) => {
            if (date && date[0] && date[1]) {
                const timeAreaArr = ref<any>([]);
                timeAreaLst.value.forEach((lstI: any, lstInd: any) => {
                    if (lstI.ATTR.val === rowItem.period) {
                        timeAreaArr.value.push({
                            timeAreaStart: lstI.FROM.val ? lstI.FROM.val : '',
                            timeAreaEnd: lstI.TO.val ? lstI.TO.val : '',
                        });
                    }
                });
                const startTime = date[0].format(dateFormat);
                const endTime = date[1].format(dateFormat);
                let iscover = false;
                let nowIndex = 0;
                let inArea = false;
                timeAreaArr.value.length > 0 &&
                    timeAreaArr.value.forEach((taaItem: any, taaIndex: any) => {
                        //选择时间在预定时间内，iscover不给赋值
                        console.log(
                            'a',
                            getLargeTiem(startTime, taaItem.timeAreaStart) ===
                                '1'
                        );
                        console.log(
                            'b',
                            getLargeTiem(startTime, taaItem.timeAreaStart) ===
                                '0'
                        );
                        console.log(
                            'c',
                            getLargeTiem(endTime, taaItem.timeAreaEnd) === '-1'
                        );
                        console.log(
                            'd',
                            getLargeTiem(endTime, taaItem.timeAreaEnd) === '0'
                        );
                        if (
                            (getLargeTiem(startTime, taaItem.timeAreaStart) ===
                                '1' ||
                                getLargeTiem(
                                    startTime,
                                    taaItem.timeAreaStart
                                ) === '0') &&
                            (getLargeTiem(endTime, taaItem.timeAreaEnd) ===
                                '-1' ||
                                getLargeTiem(endTime, taaItem.timeAreaEnd) ===
                                    '0')
                        ) {
                            console.log(false);
                            inArea = true;
                        }
                    });
                if (!inArea && timeAreaArr.value.length > 0) {
                    console.log('aaa');
                    iscover = true;
                }

                dataSource.value.forEach((item, index) => {
                    // console.log(item.eligiblePeriod)
                    if (item.key !== key) {
                        const startItemTime = item.eligiblePeriod[0].format(
                            dateFormat
                        );
                        const endItemTime = item.eligiblePeriod[1].format(
                            dateFormat
                        );
                        if (item.eligiblePeriod[0] !== '') {
                            if (
                                getLargeTiem(startTime, startItemTime) ===
                                    '1' &&
                                getLargeTiem(startTime, endItemTime) === '-1'
                            ) {
                                iscover = true;
                                console.log('bbb');
                            }
                            if (
                                getLargeTiem(endTime, startItemTime) === '1' &&
                                getLargeTiem(endTime, endItemTime) === '-1'
                            ) {
                                iscover = true;
                                console.log('ccc');
                            }
                        }
                    } else {
                        nowIndex = index;
                    }
                });
                if (iscover) {
                    message.error('You cannot choose current date.');
                    dataSource.value[nowIndex].eligiblePeriod = [];
                }
            }
        };
        const handleChangeSD = (date: any, key: any) => {
            if (date && date[0] && date[1]) {
                const startTime = date[0].format(dateFormat);
                const endTime = date[1].format(dateFormat);
                let iscover = false;
                let nowIndex = 0;
                console.log('startTime', startTime);
                dataSource.value.forEach((item, index) => {
                    // console.log(item.eligiblePeriod)
                    const startItemTime = item.submissionDate[0].format(
                        dateFormat
                    );
                    const endItemTime = item.submissionDate[1].format(
                        dateFormat
                    );
                    const startItemEPTime = item.eligiblePeriod[0].format(
                        dateFormat
                    );
                    const endItemEPTime = item.eligiblePeriod[1].format(
                        dateFormat
                    );
                    if (item.key !== key) {
                        if (item.submissionDate[0] !== '') {
                            if (
                                getLargeTiem(startTime, startItemTime) ===
                                    '0' &&
                                getLargeTiem(endTime, endItemTime) === '0'
                            ) {
                                iscover = true;
                            }
                        }
                    } else {
                        nowIndex = index;
                        if (
                            item.eligiblePeriod[0] !== '' &&
                            item.submissionDate[0] !== ''
                        ) {
                            if (
                                getLargeTiem(startItemTime, startItemEPTime) ===
                                    '-1' ||
                                getLargeTiem(endItemTime, endItemEPTime) ===
                                    '-1'
                            ) {
                                iscover = true;
                            }
                        }
                    }
                });
                if (iscover) {
                    message.error('You cannot choose current submission date.');
                    dataSource.value[nowIndex].submissionDate = [];
                }
            }
        };
        return {
            dateFormat,
            isSaved,
            // valCorresponding,
            getLargeTiem,
            timeAreaLst,
            selectPeriod,
            handleChangeSD,
            handleChangeEP,
            count,
            progDesignId,
            periodList,
            periodPgmList,
            columns,
            onDelete,
            handleAdd,
            dataSource,
            saveDocSubmissionParams,
            showData,
            claimMethodData,
            isTake,
        };
    },
});
